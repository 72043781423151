.shake {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
}

@keyframes shake {
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
    }
    20%, 80% {
        transform: translate3d(2px, 0, 0);
    }
    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
    }
    40%, 60% {
        transform: translate3d(4px, 0, 0);
    }
}

.slide-up {
    position: fixed;
    bottom: -50vh;
    left: 0;
    width: 100%;
    background: white;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
    transition: bottom 0.3s ease-in-out;
    z-index: 40;
}

.slide-up.open {
    bottom: 0;
}
